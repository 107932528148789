import gql from 'graphql-tag';

export const GET_USERS = gql`
  query GetUsers($limit: Int, $offset: Int, $where: users_bool_exp = {}) {
    items: users(order_by: {created_at: desc}, offset: $offset, limit: $limit, where: $where) {
      id
      uid
      updated_at
      created_at
      nickname
      avatar
      email
      gender
      role
      user_info {
        id
        user_id
        updated_at
        created_at
        app_version
        device_id
        fcm_token
        is_character
        latest_at
        locale
        platform
        provider
      }
    }
    users_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const GET_USER_BY_ID = gql`
  query GetUserById($where: users_bool_exp!) {
    items: users(where: $where, limit: 1) {
      id
      uid
      updated_at
      created_at
      nickname
      avatar
      email
      gender
      role
      user_info {
        id
        user_id
        updated_at
        created_at
        app_version
        device_id
        fcm_token
        is_character
        latest_at
        locale
        platform
        provider
      }
      introduction {
        birthday
        blood_type
        color
        created_at
        favorite_food
        hobby
        mbti
        specialty
        updated_at
        user_id
        worst_food
      }
      user_items(limit: 1) {
        amount
      }
    }
  }
`