import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

import firebase from "gatsby-plugin-firebase";

import { GET_CHATS, GET_CHAT_BY_ID } from './chat_repository.query';
import { Chat, Chats } from '../model/chat';

export const useGetChats = (): {
  loading: boolean,
  items: Chat[],
  refrash: Function,
} => {
  const [queryHandler, { loading, data }] = useLazyQuery<Chats>(
    GET_CHATS, {
    onError: err => {
      alert(err.message);
    }
  });

  async function call(query?: any) {
    try {
      const idToken = await firebase.auth().currentUser?.getIdToken();
      queryHandler({
        variables: {
          "limit": 500,
          "offset": 0,
          "where": {},
        },
        context: {
          headers: {
            'Authorization': `Bearer ${idToken}`
          },
        },
      });
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  }

  useEffect(() => {
    call()
  }, []);

  return { loading, items: data?.items || [], refrash: call };
}


export const useGetChatDetail = (id: string): {
  loading: boolean,
  items: Chat[],
} => {
  const [queryHandler, { loading, data }] = useLazyQuery<Chats>(
    GET_CHAT_BY_ID, {
    variables: {
      "where": {
        "id": {
          "_eq": id
        }
      }
    },
    onError: err => {
      alert(err.message);
    }
  });


  useEffect(() => {
    async function call() {
      try {
        const idToken = await firebase.auth().currentUser?.getIdToken();
        queryHandler({
          context: {
            headers: {
              'Authorization': `Bearer ${idToken}`
            },
          },
        });
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    }

    call()
  }, []);

  return { loading, items: data?.items ?? [] };
}
