import React, { FC } from 'react';
import { format } from 'date-fns'
import { Link, useParams } from '@reach/router';
import { Breadcrumb, Layout, Empty, Tag, Card, Button, Divider, Row, Col, Comment, Avatar, Image } from 'antd';
const { Content } = Layout;

import { useGetChatDetail } from '../repository/chat_repository';
import Loading from '../../../components/loading';

import { basePath } from '../../../common';
import UserInfo from '../../user/component/user_info';

const IndexPage: FC = () => {
  const params = useParams()
  console.log(params.chatId);
  const { loading, items } = useGetChatDetail(params.chatId);
  if (loading) {
    return <Loading />
  }

  console.log(items);

  if (items.length > 0) {
    return (
      <Content style={{ margin: '0 16px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item><Link to={basePath('/chat')}>채팅 상세</Link></Breadcrumb.Item>
          <Breadcrumb.Item>{params.chatId}</Breadcrumb.Item>
        </Breadcrumb>

        <Card style={{ padding: '20px' }}>
          {items[0].messages.map(message => {
            return <Comment
              style={{ paddingLeft: message.userBySendUserId.id == items[0].user_id ? 20 : 0 }}
              key={message.id}
              author={<Link to={basePath(`/users/${message.userBySendUserId.id}`)}>{message.userBySendUserId.nickname}</Link>}
              avatar={
                <Avatar
                  src={message.userBySendUserId.avatar}
                />
              }
              content={message.message}
              datetime={
                <span>{format(new Date(message.created_at), 'yyyy-MM-dd HH:mm')}</span>
              }
            />
          })}
        </Card>
      </Content>
    );
  } else {
    return (
      <Content style={{ margin: '0 16px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item><Link to={basePath('/chat')}>채팅 상세</Link></Breadcrumb.Item>
          <Breadcrumb.Item>{params.userId}</Breadcrumb.Item>
        </Breadcrumb>
        <Empty />
      </Content>
    );
  }
}

export default IndexPage;

