import React, { useState } from 'react';
import { Form, Input, Button, Row, Layout, Card } from 'antd';

import { onClickSignIn } from '../repository/signin_repository';

const tailLayout = {
  width: '100%'
};

const SignUpPage: React.FC = () => {

  const [isLoading, setLoading] = useState(false);


  const onFinish = async (values) => {
    const { email, password } = values;

    console.log('Success');
    setLoading(true)
    await onClickSignIn(email, password);
    setLoading(false)
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
      <Card title="Login" style={{ padding: '10px 20px' }}>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, type: 'email', message: 'The input is not valid id' }]}
          >
            <Input placeholder='id' />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password placeholder='password' />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" className="login-form-button" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Row>
  );
}

export default SignUpPage;