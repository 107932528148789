import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

import firebase from "gatsby-plugin-firebase";

import { Users, User, UserAggregate } from '../model/user';

import { GET_USERS, GET_USER_BY_ID } from './user_repository.query';

type Params = {
  limit: number;
  offset: number;
  category: string;
  keyword: string;
  mode: string;
  role: string;
}

export const useGetUsers = (initParams: Params): {
  loading: boolean,
  items: Array<User>,
  total: number,
  refrash: Function,
} => {
  const [queryHandler, { loading, data }] = useLazyQuery<Users>(
    GET_USERS, {
    onError: err => {
      alert(err.message);
    }
  });

  async function call(params: Params) {
    let limit = params.limit || 100;
    let offset = params.offset || 0;
    let where: any = {
      "role": {
        "_eq": params.role,
      },
    };

    if (params.category != 'all') {
      if (params.keyword) {
        if (params.category == 'name') {
          where = {
            ...where, ...{
              "nickname": {
                "_ilike": `%${params?.keyword}%`,
              },
            }
          };
        } else if (params.category == 'email') {
          where = {
            ...where, ...{
              "email": {
                "_eq": params?.keyword,
              },
            }
          };
        }
      }
    }

    if (params.mode != 'all') {
      where = {
        ...where, ...{
          "mode": {
            "_eq": params?.mode,
          },
        }
      };
    }

    try {
      const idToken = await firebase.auth().currentUser?.getIdToken();
      queryHandler({
        variables: {
          "limit": limit,
          "offset": offset,
          "where": where,
        },
        context: {
          headers: {
            'Authorization': `Bearer ${idToken}`
          },
        },
      });
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  }

  useEffect(() => {
    call(initParams)
  }, []);

  console.log(data);

  return { loading, items: data?.items || [], total: data?.users_aggregate?.aggregate?.count, refrash: call };
}

export const useGetUserDetail = (id: string): {
  loading: boolean,
  items: Array<User>,
} => {
  const [queryHandler, { loading, data }] = useLazyQuery<Users>(
    GET_USER_BY_ID, {
    variables: {
      "where": {
        "id": {
          "_eq": id,
        },
      }
    },
    onError: err => {
      alert(err.message);
    }
  });

  useEffect(() => {
    async function call() {
      try {
        const idToken = await firebase.auth().currentUser?.getIdToken();
        queryHandler({
          context: {
            headers: {
              'Authorization': `Bearer ${idToken}`
            },
          },
        });
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    }

    call()
  }, []);

  return { loading, items: data?.items ?? [] };
}

export const useUpdateUser = (): [Function, boolean] => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [mutationHandler] = useMutation(
    gql`
      mutation UpdateUserAndScore($where: users_bool_exp!, $_set: users_set_input!) {
        update_users(where: $where, _set: $_set) {
          returning {
            is_delete
            memo
          }
        }
      }
    `, {
    onCompleted: () => {
      setLoading(false);
    },
    onError: err => {
      setLoading(false);
      throw err.message;
    }
  });

  async function call(user: User) {
    setLoading(true);
    const idToken = await firebase.auth().currentUser?.getIdToken();
    mutationHandler({
      variables: {
        "where": {
          "id": {
            "_eq": user.id
          }
        },
        "_set": {
          // TODO
        },
      },
      update(cache, { data }) {

      },
      context: {
        headers: {
          'Authorization': `Bearer ${idToken}`
        },
      },
    });
  }

  return [call, isLoading];
}