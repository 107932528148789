import * as React from 'react';
import { Editor as EditorType, EditorProps } from '@toast-ui/react-editor';
import Loading from '../loading';
import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor-only.css';
import 'tui-color-picker/dist/tui-color-picker.css';

interface EditorPropsWithHandlers extends EditorProps {
  onChange?(value: string): void;
}

// const Editor = dynamic<TuiEditorWithForwardedProps>(() => import("./editor"), { ssr: false });

const EditorLazy = React.lazy(() =>
  import("./editor")
)


const EditorWithForwardedRef = React.forwardRef<EditorType | undefined, EditorPropsWithHandlers>((props, ref) => {
  const isSSR = typeof window === "undefined";

  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<Loading />}>
          <EditorLazy {...props} forwardedRef={ref as React.MutableRefObject<EditorType>} />
        </React.Suspense>
      )
      }
    </>
  )
});

interface Props extends EditorProps {
  onChange(value: string): void;

  valueType?: "markdown" | "html";
  initialEditType?: "markdown" | "wysiwyg";
}

const WysiwygEditor: React.FC<Props> = (props) => {
  const { initialValue, previewStyle, height, initialEditType, useCommandShortcut } = props;

  const editorRef = React.useRef<EditorType>();
  const handleChange = React.useCallback(() => {
    if (!editorRef.current) {
      return;
    }

    const instance = editorRef.current.getInstance();
    const valueType = props.valueType || "markdown";

    props.onChange(valueType === "markdown" ? instance.getMarkdown() : instance.getHtml());
  }, [props, editorRef]);

  return <>
    <EditorWithForwardedRef
      {...props}
      initialValue={initialValue || ""}
      previewStyle={previewStyle || "vertical"}
      height={height || "600px"}
      initialEditType={initialEditType || "markdown"}
      useCommandShortcut={useCommandShortcut || true}
      ref={editorRef}
      onChange={handleChange}
    />
  </>;
};

export default WysiwygEditor;