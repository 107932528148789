import React, { FC, useState } from 'react';
import { Button, Row, Divider, message, Space } from 'antd';

import Editor from '../../../components/editor'

import { useUpsertPolicies } from '../repository/policy_repository';
import { Policy } from '../model/policy';

const PolicyEditComponent: FC<{
  title?: string,
  type: 'privacy' | 'terms' | 'location',
  language: 'ko' | 'en'
  item: Policy,
}> = ({ title, type, language, item }) => {
  const [updateAppInfo, isLoading] = useUpsertPolicies();

  const [text, setText] = useState<any>();

  const onClick = async () => {
    try {
      if (!text) return message.error('잘못된 요청입니다.');

      await updateAppInfo({
        "objects": {
          "title": title,
          "type": type,
          "content": text.replace(/\n[ ]*/g, ''),
          "language": language,
          "updated_at": "now()"
        },
        "update_columns": ["content", "updated_at"]
      });

      message.success('수정되었습니다.');
    } catch (err) {
      message.error(err.message);
    }
  }
  if (!item) return <div />

  return (
    <>
      <Editor
        // height="auto"
        valueType="html"
        initialValue={item?.content || ''}
        initialEditType="wysiwyg"
        onChange={(data) => {
          setText(data)
        }}
      />
      <Divider />
      <Row justify="center" align="middle">
        <Space size="large">
          <Button type="primary" size="large" loading={isLoading} onClick={onClick}>수정</Button>
          <a target="_blank" href={`/policy/${type}/?hl=${language}`}>보기</a>
        </Space>

      </Row>
    </>
  )
}

export default PolicyEditComponent;