import gql from 'graphql-tag';

export const GET_LETTERS = gql`
  query GetLetter($limit: Int, $offset: Int, $where: letters_bool_exp = {}) { 
    items: letters(order_by: {created_at: desc}, offset: $offset, limit: $limit, where: $where) {
      id
      updated_at
      created_at
      message
      user_id
      user {
        nickname
      }
    }
    letters_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const GET_LETTERS_BY_ID = gql`
  query GetLetterByUserID($where: letters_bool_exp = {}, $distinct_on:[letters_select_column!]) { 
    items: letters(order_by: {created_at: desc}, distinct_on: $distinct_on, where: $where) {
      id
      message
      receive_user_id
      send_type
      send_user_id
      updated_at
      is_view
      created_at
      user {
        nickname
      }
    }
  }
`

export const GET_LETTERS_BY_USER_ID = gql`
  query GetLetterByUserID($where: letters_bool_exp = {}) { 
    items: letters(order_by: {created_at: desc}, where: $where) {
      id
      updated_at
      created_at
      message
      user_id
      user {
        nickname
      }
    }
  }
`