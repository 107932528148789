import React, { useState } from 'react';
import { Breadcrumb, Layout, Input, Form, Select, Button, Radio, InputNumber } from 'antd';
import { addManager } from '../repository/manager_repository';
import { randomWords } from '../component/random_words';

import './manager_add_screen.less';

const { Content } = Layout;
const { Option } = Select;

const ManagerAddPage: React.FC<{ handleCancel: Function, lastIndex: number }> = ({ handleCancel, lastIndex }) => {
	const [form] = Form.useForm();

	const [isLoading, setLoading] = useState(false);

	const adjective: string = randomWords['adjective'][Math.floor(Math.random() * randomWords['adjective'].length)];
	const noun: string = randomWords['noun'][Math.floor(Math.random() * randomWords['noun'].length)];

	const randomAge: number = Math.floor(Math.random() * (40 - 18)) + 18;

	const onFinish = async (values: any) => {
		setLoading(true);

		await addManager(values);

		handleCancel();
		setLoading(false);
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log('onFinishFailed', errorInfo);
	};

	const onGenderChange = (value: string) => {
		switch (value) {
			case 'male':
				form.setFieldsValue({ note: 'male' });
				return;
			case 'female':
				form.setFieldsValue({ note: 'female' });
				return;
		}
	};

	return (
		<Content style={{ margin: '0 16px' }}>
			<Breadcrumb style={{ margin: '16px 0' }}>
				<Breadcrumb.Item>매니저 생성하기</Breadcrumb.Item>
			</Breadcrumb>
			<div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
				<Form
					form={form}
					name="basic"
					labelCol={{ offset: 0, span: 0 }}
					wrapperCol={{ offset: 0, span: 0 }}
					initialValues={{ remember: true }}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
				>
					<Form.Item
						name="email"
						label="이메일"
						initialValue={`manager${lastIndex + 1}@mbti.io`}
						rules={[{ required: true, message: "required email" }]}
						wrapperCol={{ offset: 0, span: 0 }}
					>
						<Input size="middle" style={{ width: 200 }} allowClear={true} />
					</Form.Item>
					<Form.Item
						name="password"
						label="비밀번호"
						initialValue="nucamanager2@"
						rules={[{ required: true, message: "required password" }]}
						wrapperCol={{ offset: 0, span: 0 }}
					>
						<Input size="middle" style={{ width: 200 }} allowClear={true} />
					</Form.Item>
					<Form.Item
						name="nickname"
						label="닉네임"
						initialValue={`${adjective} ${noun}`}
						rules={[{ required: true, message: "required nickname" }]}
						wrapperCol={{ offset: 0, span: 0 }}
					>
						<Input size="middle" style={{ width: 200 }} allowClear={true} />
					</Form.Item>
					<Form.Item
						name="age"
						label="나이"
						initialValue={`${randomAge}`}
						rules={[{ required: true, message: "required age" }]}
						wrapperCol={{ offset: 0, span: 0 }}
					>
						<InputNumber size="middle" style={{ width: 200 }} maxLength={2} />
					</Form.Item>
					<Form.Item
						name="gender"
						label="성별"
						initialValue="female"
						rules={[{ required: true, message: "required gender" }]}
						wrapperCol={{ offset: 0, span: 0 }}
					>
						<Select
							onChange={onGenderChange}
							style={{ width: 200 }}>
							<Option value="male">남자</Option>
							<Option value="female">여자</Option>
						</Select>
					</Form.Item>
					<Form.Item
						name="personality"
						label="MBTI"
						rules={[{ required: true, message: "required MBTI" }]}
						wrapperCol={{ offset: 0, span: 0 }}
					>
						<Radio.Group style={{ width: 400 }}>
							<Radio value="istj">istj</Radio>
							<Radio value="isfj">isfj</Radio>
							<Radio value="infj">infj</Radio>
							<Radio value="intj">intj</Radio>
							<Radio value="istp">istp</Radio>
							<Radio value="isfp">isfp</Radio>
							<Radio value="infp">infp</Radio>
							<Radio value="intp">intp</Radio>
							<Radio value="estp">estp</Radio>
							<Radio value="esfp">esfp</Radio>
							<Radio value="enfp">enfp</Radio>
							<Radio value="entp">entp</Radio>
							<Radio value="estj">estj</Radio>
							<Radio value="esfj">esfj</Radio>
							<Radio value="enfj">enfj</Radio>
							<Radio value="entj">entj</Radio>
						</Radio.Group>
					</Form.Item>
					<Form.Item
						wrapperCol={{ offset: 0, span: 0 }}
					>
						<Button type="primary" htmlType="submit" loading={isLoading}>
							생성하기
						</Button>
					</Form.Item>
				</Form>
			</div >
		</Content >
	);
}

export default ManagerAddPage;