import React, { FC, useState, useEffect } from 'react';
import { format } from 'date-fns'
import { Table, Breadcrumb, Layout, Tag, Input, Select, Divider, Space, Radio } from 'antd';
import { Link, navigate } from '@reach/router';
import { useQueryParams, StringParam, withDefault } from 'use-query-params';

import { stateStr, basePath } from '../../../common';
import { useGetChats } from '../repository/chat_repository';
import Loading from '../../../components/loading';
import { Chat } from '../model/chat';
import { Message } from '../model/message';

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;

const ListView: FC = () => {
  const { loading, items, refrash } = useGetChats();

  const data = items.map((item, index) => {
    return {
      key: item.id,
      ...item,
    }
  })

  const columns = [
    {
      title: '순서',
      dataIndex: 'id',
      key: 'id',
      render: (id: number, row: Chat, index: number) => (
        <span>{items.length - index}</span>
      )
    },
    {
      title: '내용',
      dataIndex: 'messages',
      key: 'messages',
      render: (messages: Message[]) => {
        return <span>{messages[0]?.message ?? '-'}</span>
      }
    },
    {
      title: '생성일',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: string) => (
        <span>{format(new Date(created_at), 'yyyy-MM-dd HH:mm')}</span>
      )
    },
  ];

  return (
    <Content style={{ margin: '0 16px' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>채팅</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <Table
          pagination={{
            pageSize: 100
          }}
          dataSource={data}
          columns={columns}
          loading={loading}
          onRow={(r) => ({
            onClick: () => navigate(basePath(`/chat/${r.id}`)),
          })}
        />
      </div>
    </Content>
  );
}

export default ListView;
