import React, { useState } from 'react';
import { Layout, Menu, Breadcrumb } from 'antd';
import {
  DesktopOutlined,
  UserOutlined,
  LogoutOutlined,
  UnlockOutlined,
  BankOutlined,
  AppstoreOutlined,
  NotificationOutlined,
  MessageOutlined,
  SendOutlined,
} from '@ant-design/icons';

import { navigate, useLocation, Link } from "@reach/router"

import './layout.less';

import { signOut } from '../../features/signin/repository/signin_repository';

import { basePath, getQueryStringObject } from '../../common';

const { Header, Content, Footer, Sider, } = Layout;
const { SubMenu } = Menu;

const LayoutComponent: React.FC = ({ children }) => {
  const location = useLocation();

  const [collapsed, setCollapsed] = useState(false);
  const [selectKeys, setSelectKeys] = useState(['sub4']);

  const onClickLogout = () => {
    if (confirm('로그아웃을 하시겠습니까?')) {
      signOut();
    }
  }

  function getSelectKeys(): Array<string> {
    let keys = ['1'];

    switch (location.pathname.split('/')[2]) {
      case 'users':
        keys = ['2'];
        break;
      case 'notice':
        keys = ['3'];
        break;
      case 'privacy':
        keys = ['sub1_1'];
        break;
      case 'terms':
        keys = ['sub1_2'];
        break;
      case 'chat':
        keys = ['4'];
        break;
      case 'managers':
        keys = ['5'];
        break;
      case 'letters':
        keys = ['6'];
        break;
      default:
    }

    return keys;
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}>
        <div className="logo" style={{
          height: 32, margin: 16,
          background: 'rgba(255, 255, 255, 0.3)'
        }}>
          <div style={{
            color: '#fff', fontWeight: 'bold', textAlign: 'center',
            paddingTop: 5
          }}>{'관리자'}</div>
        </div>
        <Menu theme="dark" mode="inline" selectedKeys={getSelectKeys()}>
          <Menu.Item key="1" icon={<DesktopOutlined />} onClick={() => navigate(basePath())}>
            대시보드
          </Menu.Item>
          <Menu.Item key="3" icon={<NotificationOutlined />} onClick={() => navigate(basePath('/notice'))}>
            공지사항
          </Menu.Item>
          <SubMenu
            key="sub1"
            title={
              <span>
                <UnlockOutlined />
                <span>약관관리</span>
              </span>
            }
          >
            <Menu.Item key="sub1_1" onClick={() => navigate(basePath('/privacy'))}>개인정보</Menu.Item>
            <Menu.Item key="sub1_2" onClick={() => navigate(basePath('/terms'))}>이용약관</Menu.Item>
          </SubMenu>
          <Menu.Item key="5" icon={<UserOutlined />} onClick={() => navigate(basePath('/managers'))}>
            매니저
          </Menu.Item>
          <Menu.Item key="2" icon={<UserOutlined />} onClick={() => navigate(basePath('/users?page=1'))}>
            사용자
          </Menu.Item>
          <Menu.Item key="6" icon={<SendOutlined />} onClick={() => navigate(basePath('/letters?page=1'))}>
            편지
          </Menu.Item>
          <Menu.Item key="4" icon={<MessageOutlined />} onClick={() => navigate(basePath('/chat'))}>
            채팅
          </Menu.Item>
          <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={onClickLogout}>
            로그아웃
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        {children}
        <Footer style={{ textAlign: 'center' }}>Created by Nuca, Inc. 🙂</Footer>
      </Layout>
    </Layout >
  )
};

export default LayoutComponent;