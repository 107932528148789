import React, { FC, useState } from 'react';
import { format } from 'date-fns'
import { Button, Table, Breadcrumb, Layout, Input, Select, Divider, Space, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { navigate } from '@reach/router';
import firebase from "gatsby-plugin-firebase";

import { basePath } from '../../../common';
import { useGetUsers } from '../../user/repository/user_repository';
import { User } from '../../user/model/user';
import { useQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params';
import ManagerAdd from './manager_add_screen';

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;
const { confirm } = Modal;

const ListView: FC = () => {
  const [query, setQuery] = useQueryParams({
    mode: withDefault(StringParam, 'all'),
    category: withDefault(StringParam, 'all'),
    keyword: withDefault(StringParam, ''),
    offset: withDefault(NumberParam, 0),
    limit: withDefault(NumberParam, 500),
    role: withDefault(StringParam, 'manager'),
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { loading, items, refrash } = useGetUsers({
    limit: query.limit,
    offset: query.offset,
    category: query.category,
    keyword: query.keyword,
    mode: query.mode,
    role: query.role,
  });

  const onSearch = (value: string) => {
    refrash({
      limit: query.limit,
      offset: query.offset,
      keyword: value,
      category: query.category,
      mode: query.mode,
      role: query.role,
    })

    setQuery({ keyword: value });
  }

  const onChange = (value: string) => {
    if (value == 'all') {
      refrash({
        limit: query.limit,
        offset: query.offset,
        keyword: '',
        category: 'all',
        mode: query.mode,
        role: query.role,
      })
    }
    setQuery({ category: value });
  }

  const onChangeMode = (e: any) => {

    refrash({
      limit: query.limit,
      offset: query.offset,
      keyword: query.keyword,
      category: query.category,
      mode: e.target.value,
      role: query.role,
    })

    setQuery({ mode: e.target.value });
  }

  const data = items.map((item, index) => {
    return {
      key: item.id,
      ...item,
    }
  })

  const columns: any[] = [
    {
      title: '순서',
      dataIndex: 'id',
      key: 'id',
      render: (id: number, row: User, index: number) => (
        <span>{items.length - index}</span>
      )
    },
    {
      title: '이름',
      dataIndex: 'nickname',
      key: 'nickname',
      render: (name: string, r: User) => (
        <a onClick={() => navigate(basePath(`/users/${r.id}`))}>
          {name || '-'}
        </a>
      )
    },
    {
      title: '이메일',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: '가입형태',
      dataIndex: 'provider',
      key: 'provider',
    },
    {
      title: '생성일',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: string) => (
        <span>{format(new Date(created_at), 'yyyy-MM-dd HH:mm')}</span>
      )
    },
    {
      title: '설정',
      dataIndex: 'uid',
      key: 'uid',
      render: (uid: string) => (
        <Button danger onClick={async () => {
          confirm({
            title: '삭제를 진행 할까요?',
            icon: <ExclamationCircleOutlined />,
            content: '삭제 시 복구가 불가능합니다.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
              setLoading(true);

              try {
                const httpsCallable = firebase.functions().httpsCallable('withdrawal');

                await httpsCallable({
                  'uid': uid,
                });

                message.success('withdrawal success');

                window.location.reload();
              } catch (err) {
                message.error(err.message);
              } finally {
                setLoading(false);
              }
            },
            onCancel() { },
          });
        }}
        >
          삭제
        </Button>
      )
    },
  ];

  return (
    <Content style={{ margin: '0 16px' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>매니저</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <Space direction="horizontal">
          <Input.Group compact>
            <Select value={query.category} onChange={onChange} style={{ width: 80 }} >
              <Option value="all">전체</Option>
              <Option value="name">이름</Option>
              <Option value="email">이메일</Option>
            </Select>
            <Search placeholder="검색" loading={loading} disabled={query.category == 'all'} onSearch={onSearch} style={{ width: 200 }} />
          </Input.Group>
          <Button type="primary" size="middle" onClick={() => setIsModalVisible(true)}>
            매니저 생성하기
          </Button>
        </Space>
        <Divider />
        <Table
          pagination={{
            pageSize: 100
          }}
          dataSource={data}
          columns={columns}
          loading={loading}
        />
        <Modal
          title="매니저 생성하기"
          visible={isModalVisible}
          onOk={() => setIsModalVisible(false)}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <ManagerAdd lastIndex={items.length} handleCancel={() => {
            setIsModalVisible(false);

            window.location.reload();
          }} />
        </Modal>
      </div >
    </Content >
  );
}

export default ListView;
