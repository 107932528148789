import React, { FC, useState } from 'react';
import { format } from 'date-fns'
import { Table, Breadcrumb, Layout, Tag, Input, Select, Divider, Pagination, Space } from 'antd';
import { Link, navigate } from '@reach/router';
import {
  AppleFilled,
  GoogleOutlined,
  MailFilled
} from '@ant-design/icons';

import { stateStr, basePath } from '../../../common';
import { useGetUsers } from '../repository/user_repository';
import Loading from '../../../components/loading';
import { User } from '../model/user';
import { useQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params';

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;

const ListView: FC = () => {
  const limit = 100;
  const [query, setQuery] = useQueryParams({
    mode: withDefault(StringParam, 'all'),
    category: withDefault(StringParam, 'all'),
    keyword: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    role: withDefault(StringParam, 'user'),
  });

  const { loading, items, total, refrash } = useGetUsers({
    limit: limit,
    offset: (query.page - 1) * limit,
    category: query.category,
    keyword: query.keyword,
    mode: query.mode,
    role: query.role,
  });

  const onSearch = (value: string) => {
    refrash({
      limit: limit,
      offset: (query.page - 1) * limit,
      keyword: value,
      category: query.category,
      mode: query.mode,
      role: query.role,
    })

    setQuery({ keyword: value });
  }

  const onChange = (value: string) => {
    if (value == 'all') {
      refrash({
        limit: limit,
        offset: (query.page - 1) * limit,
        keyword: '',
        category: 'all',
        mode: query.mode,
        role: query.role,
      })
      setQuery({ category: 'all', keyword: '' });
    }
    setQuery({ category: value });
  }

  const onChangeMode = (e: any) => {
    setQuery({ mode: e.target.value });
  }

  const onChangePage = (page: number) => {
    refrash({
      limit: limit,
      offset: (page - 1) * limit,
      keyword: '',
      category: 'all',
      mode: query.mode,
      role: query.role,
    })
    setQuery({ page: page });
  }

  const data = items.map((item, index) => {
    console.log(item);
    return {
      key: item.id,
      ...item,
    }
  })

  const columns: any[] = [
    {
      title: '순서',
      dataIndex: 'id',
      key: 'id',
      render: (id: number, _, index: number) => (
        <span>{total - (index + (query.page - 1) * limit)}</span>
      )
    },
    {
      title: '이름',
      dataIndex: 'nickname',
      key: 'nickname',
      render: (name: string) => (
        <span>{name || '-'}</span>
      )
    },
    {
      title: '이메일',
      dataIndex: 'email',
      key: 'email',
      render: (email: string, row: User) => {
        if (row.user_info.provider === 'email') {
          return <span><MailFilled /> {email || '-'}</span>
        } else if (row.user_info.provider === 'google') {
          return <span><GoogleOutlined /> {email || '-'}</span>
        } else if (row.user_info.provider === 'apple') {
          return <span><AppleFilled /> {email || '-'}</span>
        } else {
          return <span>{row.user_info.provider || '-'} {email || '-'}</span>
        }
      }
    },
    {
      title: '생성일',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: string) => (
        <span>{format(new Date(created_at), 'yyyy-MM-dd HH:mm')}</span>
      )
    },
  ];

  return (
    <Content style={{ margin: '0 16px' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>사용자 / {total}명</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <Space direction="vertical">
          <Input.Group compact>
            <Select value={query.category} onChange={onChange} style={{ width: 80 }} >
              <Option value="all">전체</Option>
              <Option value="name">이름</Option>
              <Option value="email">이메일</Option>
            </Select>
            <Search placeholder="검색" loading={loading} disabled={query.category == 'all'} onSearch={onSearch} style={{ width: 200 }} />
          </Input.Group>
        </Space>
        <Divider />
        <Table
          pagination={false}
          dataSource={data}
          columns={columns}
          loading={loading}
          onRow={(r) => ({
            onClick: () => navigate(basePath(`/users/${r.id}`)),
          })}
        />
        <Pagination current={query.page} total={total} onChange={onChangePage} pageSize={limit} pageSizeOptions={[]} />
      </div>
    </Content>
  );
}

export default ListView;
