import { navigate } from 'gatsby';
import { message } from 'antd';
import firebase from "gatsby-plugin-firebase";
import { basePath } from '../../../common';

export const addManager = async (values: any) => {
	try {
		let nowDate: Date = new Date();
		nowDate.setFullYear((nowDate.getFullYear() + 1) - values.age);

		const httpsCallable = firebase.functions().httpsCallable('managerSign');

		await httpsCallable({
			'email': values.email,
			'password': values.password,
			'nickname': values.nickname,
			'birthday': `${nowDate.toUTCString()}`,
			'gender': values.gender,
			'personality': values.personality,
		});

		navigate(basePath('/managers'));
	} catch (err) {
		message.error(err);
	}
}