import { useLazyQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';

import firebase from "gatsby-plugin-firebase";

import { Policy } from '../model/policy';

export const useGetPolicies = (variables?: any): {
  loading: boolean,
  items: Array<Policy>,
  refrash: Function,
} => {
  const [queryHandler, { loading, data }] = useLazyQuery<{
    items: Array<Policy>;
  }>(
    gql`
      query GetPolicies($where: policies_bool_exp!) {
        items: policies(limit: 10, where: $where) {
          content
          created_at
          id
          language
          title
          type
          updated_at
        }
      }
    `, {
    onError: err => {
      alert(err.message);
    }
  });

  async function call(variables?: any) {
    try {
      const idToken = await firebase.auth().currentUser?.getIdToken();
      queryHandler({
        variables: variables,
        context: {
          headers: {
            'Authorization': `Bearer ${idToken}`
          },
        },
      });
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  }

  useEffect(() => {
    call(variables)
  }, []);

  return { loading, items: data?.items || [], refrash: call };
}

export const useUpsertPolicies = (): [Function, boolean] => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [mutationHandler] = useMutation(
    gql`
      mutation UpsertPolicies($objects: [policies_insert_input!]!, $update_columns: [policies_update_column!]!) {
        insert_policies(on_conflict: {constraint: policies_type_language_key, update_columns: $update_columns}, objects: $objects) {
          affected_rows
        }
      }
    `, {
    onCompleted: () => {
      setLoading(false);
    },
    onError: err => {
      setLoading(false);
      throw err.message;
    }
  });

  async function call(variables: any) {
    setLoading(true);
    const idToken = await firebase.auth().currentUser?.getIdToken();
    mutationHandler({
      variables: variables,
      context: {
        headers: {
          'Authorization': `Bearer ${idToken}`
        },
      },
    });
  }

  return [call, isLoading];
}