import React from 'react';
import { Router } from '@reach/router';

import useCheckAuth from '../hooks/useCheckAuth';

// router
import BasicRoute from '../core/route/basicRoute';
import PrivateRoute from '../core/route/privateRoute';

// component
import Layout from '../components/layout';
import Loading from '../components/loading';

// screen
import DashboardScreen from '../features/dashboard';
import SigninScreen from '../features/signin/screen/signin_screen';
import UserListScreen from '../features/user/screen/user_screen';
import UserDetailScreen from '../features/user/screen/user_detail_screen';
import TermsScreen from '../features/policy/screen/terms_screen';
import PrivacyScreen from '../features/policy/screen/privacy_screen';
import ChatScreen from '../features/chat/screen/list';
import ChatDetailScreen from '../features/chat/screen/detail';
import ManagerListScreen from '../features/manager/screen/manager_screen';
import LetterScreen from '../features/letter/screen/letter_screen';

import { basePath } from '../common';

const IndexPage: React.FC = () => {

  const { authState: { isLoading, isLoggedIn } } = useCheckAuth();
  if (isLoading) {
    return (
      <Loading />
    )
  } else {
    if (isLoggedIn) {
      return (<Layout>
        <Router basepath={basePath()}>
          <PrivateRoute default component={DashboardScreen} />
          <PrivateRoute path="/users" component={UserListScreen} />
          <PrivateRoute path="/users/:userId" component={UserDetailScreen} />
          <PrivateRoute path="/terms" component={TermsScreen} />
          <PrivateRoute path="/privacy" component={PrivacyScreen} />
          <PrivateRoute path="/chat" component={ChatScreen} />
          <PrivateRoute path="/chat/:chatId" component={ChatDetailScreen} />
          <PrivateRoute path="/managers" component={ManagerListScreen} />
          <PrivateRoute path="/letters" component={LetterScreen} />
        </Router>
      </Layout>
      )
    } else {
      return (
        <Router basepath={basePath()}>
          <BasicRoute default component={SigninScreen} />
        </Router>
      );
    }
  }

  return (
    <Loading />
  )
};

export default IndexPage;
