import React, { FC, useState } from 'react';
import { format } from 'date-fns'
import { Table, Breadcrumb, Layout, Tag, Input, Select, Divider, Pagination, Space } from 'antd';
import { Link, navigate } from '@reach/router';

import { stateStr, basePath } from '../../../common';
import { useGetLettersByUserID } from '../repository/letter_repository';
import Loading from '../../../components/loading';
import { Letter } from '../model/letter';
import { User } from '../../user/model/user';

const { Content } = Layout;

const LetterList: FC<{ user: User }> = ({ user }) => {
  const { loading, items, refrash } = useGetLettersByUserID({
    where: {
      "user_id": { "_eq": user.id },
    },
  });

  const data = items.map((item, index) => {
    return {
      key: item.id,
      ...item,
    }
  })

  const columns: any[] = [
    {
      title: '보낸 사용자',
      dataIndex: 'send_user_id',
      key: 'send_user_id',
      render: (value: string, r: Letter,) => (
        <a onClick={() => navigate(basePath(`/users/${value}`))}>{r.user.nickname || '-'}</a>
      )
    },
    {
      title: '내용',
      dataIndex: 'message',
      key: 'message',
      render: (value: string) => (
        <span>{value || '-'}</span>
      )
    },
    {
      title: '생성일',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: string) => (
        <span>{format(new Date(created_at), 'yyyy-MM-dd HH:mm')}</span>
      )
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={data}
      columns={columns}
      loading={loading}
    />
  );
}


export default LetterList;