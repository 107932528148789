import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

import firebase from "gatsby-plugin-firebase";

import { Letters, Letter } from '../model/letter';

import { GET_LETTERS, GET_LETTERS_BY_ID, GET_LETTERS_BY_USER_ID } from './letter_repository.query';

type Params = {
  limit: number;
  offset: number;
  keyword: string;
}

type DetailParams = {
  where: any;
  distinct_on?: string;
}

export const useGetLetters = (initParams: Params): {
  loading: boolean,
  items: Array<Letter>,
  total: number,
  refrash: Function,
} => {
  const [queryHandler, { loading, data }] = useLazyQuery<Letters>(
    GET_LETTERS, {
    onError: err => {
      alert(err.message);
    }
  });

  async function call(params: Params) {
    let limit = params.limit || 100;
    let offset = params.offset || 0;
    let where: any = {};

    if (params.keyword) {
      where = {
        ...where, ...{
          "message": {
            "_ilike": `%${params?.keyword}%`,
          },
        }
      };
    }


    try {
      const idToken = await firebase.auth().currentUser?.getIdToken();
      queryHandler({
        variables: {
          "limit": limit,
          "offset": offset,
          "distinct_on": 'created_at',
          "where": where,
        },
        context: {
          headers: {
            'Authorization': `Bearer ${idToken}`
          },
        },
      });
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  }

  useEffect(() => {
    call(initParams)
  }, []);

  console.log(data);

  return { loading, items: data?.items || [], total: data?.letters_aggregate?.aggregate?.count, refrash: call };
}

export const useGetLettersByID = (initParams: DetailParams): {
  loading: boolean,
  items: Array<Letter>,
  refrash: Function,
} => {
  const [queryHandler, { loading, data }] = useLazyQuery<Letters>(
    GET_LETTERS_BY_ID, {
    onError: err => {
      alert(err.message);
    }
  });

  async function call(params: DetailParams) {
    try {
      const idToken = await firebase.auth().currentUser?.getIdToken();
      queryHandler({
        variables: {
          "distinct_on": params.distinct_on,
          "where": params.where,
        },
        context: {
          headers: {
            'Authorization': `Bearer ${idToken}`
          },
        },
      });
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  }

  useEffect(() => {
    call(initParams)
  }, []);

  console.log(data);

  return { loading, items: data?.items || [], refrash: call };
}


export const useGetLettersByUserID = (initParams: DetailParams): {
  loading: boolean,
  items: Array<Letter>,
  refrash: Function,
} => {
  const [queryHandler, { loading, data }] = useLazyQuery<Letters>(
    GET_LETTERS_BY_USER_ID, {
    onError: err => {
      alert(err.message);
    }
  });

  async function call(params: DetailParams) {
    try {
      const idToken = await firebase.auth().currentUser?.getIdToken();
      queryHandler({
        variables: {
          "distinct_on": params.distinct_on,
          "where": params.where,
        },
        context: {
          headers: {
            'Authorization': `Bearer ${idToken}`
          },
        },
      });
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  }

  useEffect(() => {
    call(initParams)
  }, []);

  console.log(data);

  return { loading, items: data?.items || [], refrash: call };
}