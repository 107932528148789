export enum STATE_TYPE {
  REQUEST = 'request',
  PENDING = 'pending',
  APPROVE = 'approve',
  SUCCESS = 'success',
  REJECT = 'reject',
}

export const basePath = function (path?: string) {
  return `/console${path || ''}`;
}

export const stateStr = function (index: string) {
  if (index == null) {
    return {
      value: '가입',
      color: 'default',
    }
  }

  const obj = {
    'request': {
      value: '요청',
      color: 'gold',
    },
    'pending': {
      value: '대기',
      color: 'orange',
    },
    'approve': {
      value: '승인',
      color: 'processing',
    },
    'success': {
      value: '성공',
      color: 'success',
    },
    'reject': {
      value: '거부',
      color: 'error',
    },
    'is_delete': {
      value: '삭제',
      color: '#f00',
    },
    'is_block': {
      value: '차단',
      color: '#222',
    },
    'is_private': {
      value: '비공개',
      color: '#f50',
    },
  }

  if (obj[index] == undefined) return {
    value: '추적 사용자',
    color: 'error',
  }

  return obj[index]
}

export const arraryToVarchar = function (arr: Array<any>) {
  if (arr === null) return null;

  var str = arr.reduce((acc, value, index) => {
    if (index == 0) {
      if (value) {
        return acc + value

      } else {
        return acc + `""`
      }

    } else {
      if (value) {
        return acc + `,${value}`

      } else {
        return acc + `,""`
      }
    }
  }, "")

  return `{${str}}`
}

export const genderType = {
  'both': {
    value: '모두',
    color: '#222222'
  },
  'other': {
    value: '기타',
    color: '#222222'
  },
  'female': {
    value: '여성',
    color: '#ff3860',
  },
  'male': {
    value: '남성',
    color: '#3273dc'
  },
}

export const languageType = {
  'ko': {
    value: '한국',
  },
  'en': {
    value: '미국',
  },
}

export const numberWithCommas = function (x: any, noneStr?: string) {
  if (!x) return noneStr === undefined ? 0 : noneStr
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export const getQueryStringObject = function (path: string) {
  const a: any = path.substr(1).split('&');
  if (a == "") return {};
  var b = {};
  for (var i = 0; i < a.length; ++i) {
    var p = a[i].split('=', 2);
    if (p.length == 1)
      b[p[0]] = "";
    else
      b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
  }
  return b;
}