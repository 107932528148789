import { useState, useEffect } from 'react';

import firebase from "gatsby-plugin-firebase";

interface Auth {
  isLoading: boolean,
  isLoggedIn: boolean,
  token: string | null,
}

function useCheckAuth() {
  const [authState, setAuthState] = useState({ isLoading: true, isLoggedIn: false, token: null } as Auth);
  const url = typeof window !== 'undefined' ? window.location.href : ''

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        let token = await user.getIdToken();
        const idTokenResult = await user.getIdTokenResult();
        const isAdmin = idTokenResult.claims["admin"];
        if (isAdmin) {
          setAuthState({ isLoading: false, isLoggedIn: true, token });
        } else {
          setAuthState({ isLoading: false, isLoggedIn: false, token: null });
        }
      } else {
        setAuthState({ isLoading: false, isLoggedIn: false, token: null });
      }
    });
  }, [url]);

  return { authState };
}

export default useCheckAuth;
