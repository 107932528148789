export const randomWords: any = {
  "adjective": [
    "강직한",
    "고요한",
    "고운",
    "기특한",
    "깜찍한",
    "근면한",
    "귀여운",
    "관대한",
    "깔끔한",
    "꾸준한",
    "긍정적인",
    "깡이 있는",
    "겸손한",
    "검소한",
    "공손한",
    "기운찬",
    "놀라운",
    "넉넉한",
    "남자다운",
    "느긋한",
    "재치있는",
    "낙천적인",
    "낭만적인",
    "다정한",
    "당당한",
    "든든한",
    "다재다능한",
    "또렷한",
    "다양한",
    "단호한",
    "대담한",
    "리더쉽 있는",
    "주관적인",
    "믿음직한",
    "명랑한",
    "마음이 넓은",
    "매력적인",
    "맑은",
    "멋진",
    "말을 잘하는",
    "반듯한",
    "발랄한",
    "부드러운",
    "빼어난",
    "분별력 있는",
    "배려심이 깊은",
    "밝은",
    "배짱있는",
    "부지런한",
    "사심없는",
    "붙임성 있는",
    "산뜻한",
    "수려한",
    "순진무구한",
    "순한",
    "싱그러운",
    "선한",
    "시원시원한",
    "사교적인",
    "섬세한",
    "사랑스러운",
    "성실한",
    "순수한",
    "소신있는",
    "섹시한",
    "사려깊은",
    "소탈한",
    "상냥한",
    "생기있는",
    "솔직한",
    "신중한",
    "싹싹한",
    "아리따운",
    "어여쁜",
    "예쁜",
    "용감한",
    "우아한",
    "위대한",
    "용기있는",
    "유능한",
    "유쾌한",
    "상쾌한",
    "명쾌한",
    "통쾌한",
    "이해심 많은",
    "아름다운",
    "여유로운",
    "원만한",
    "육감적인",
    "여성스러운",
    "입이 무거운",
    "에너지가 넘치는",
    "유머러스한",
    "융통성 있는",
    "적극적인",
    "직선적인",
    "정의로운",
    "조용한",
    "재미있는",
    "정직한",
    "존귀한",
    "지혜로운",
    "자애로운",
    "잘 웃는",
    "자신감 있는",
    "자유로운",
    "지적인",
    "절약하는",
    "정숙한",
    "진취적인",
    "착한",
    "청초한",
    "창의적인",
    "침착한",
    "책임감 있는",
    "차분한",
    "친근한",
    "친절한",
    "쾌활한",
    "카리스마 있는",
    "튼튼한",
    "털털한",
    "편안한",
    "평화로운",
    "포근한",
    "포용력 있는",
    "훌륭한",
    "활동적인",
    "화사한",
    "온화한",
    "화끈한",
    "합리적인",
    "헌신적인",
    "활발한",
    "호감이 가는",
    "기쁜",
    "벅찬",
    "흐뭇한",
    "짜릿한",
    "반가운",
    "후련한",
    "살맛나는",
    "아늑한",
    "흥분되는",
    "안전한",
    "끝내주는",
    "날아갈듯한",
    "괜찮은",
    "쌈박한",
    "정다운",
    "그리운",
    "따사로운",
    "감미로운",
    "황홀한",
    "상큼한",
    "달콤한",
    "뭉클한",
    "애틋한",
    "애석한",
    "야릇한",
    "약오르는",
    "깨끗한",
    "부담스러운",
    "미칠것같은",
    "당황스러운",
    "긴장되는",
    "기분이 좋은",
    "걱정스러운",
    "무거운",
    "캄캄한",
    "마음을 열고싶은",
    "마음이 급한",
    "들뜬",
    "혼란스러운",
    "겁나는",
    "섬뜩한",
    "난처한",
    "떨리는",
    "살벌한",
    "즐거운",
    "활기찬",
    "생생한",
    "열렬한",
    "사이좋은",
    "패기있는",
    "강렬한",
    "감성적인",
    "부끄러운",
    "수줍은",
    "민망한",
    "어색한",
    "묘한",
    "건강한",
    "고마운",
    "색다른",
    "따뜻한",
    "선명한",
    "맛있는",
    "복잡한",
    "세련된",
    "배고픈",
    "바쁜",
    "매운",
    "달달한",
    "비슷한",
    "미안한",
    "어려운",
    "배부른",
    "시끄러운",
    "싱거운",
    "아픈",
    "어두운",
    "알맞은",
    "잘생긴",
    "추운",
    "더운",
    "소중한",
    "중요한",
    "하얀",
    "필요한",
    "가난한",
    "가벼운",
    "간단한",
    "가까운",
    "강한",
    "갑작스러운",
    "거대한",
    "가득한",
    "건전한",
    "건조한",
    "게으른",
    "소박한",
    "강력한",
    "고통스러운",
    "굉장한",
    "엄청난",
    "궁금한",
    "그럴듯한",
    "귀중한",
    "신기한",
    "꼼꼼한",
    "시원한",
    "매혹적인",
    "날카로운",
    "눈부신",
    "독특한",
    "특별한",
    "따스한",
    "똑같은",
    "뛰어난",
    "명확한",
    "바람직한",
    "심심한",
    "아까운",
    "씩씩한",
    "후회하는",
    "야한",
    "엄격한",
    "어지러운",
    "엄숙한",
    "영원한",
    "엉뚱한",
    "완전한",
    "예의바른",
    "이상한",
    "은은한",
    "유명한",
    "유리한",
    "익숙한",
    "자랑스러운",
    "자연스러운",
    "적당한",
    "진실한",
    "차가운",
    "창피한",
    "진지한",
    "중대한",
    "철저한",
    "촌스러운",
    "충분한",
    "작은",
    "커다란",
    "탁월한",
    "특이한",
    "편리한",
    "평범한",
    "풍부한",
    "한가한",
    "화려한",
    "행복한",
    "흥미로운",
    "관용적인",
    "근심이 없는",
    "내성적인",
    "너그러운",
    "마음씨가 고운",
    "마음이 따뜻한",
    "민첩한",
    "배려깊은",
    "순발력 있는",
    "신뢰할 수 있는",
    "아낌없이 주는",
    "순종적인",
    "야망에 찬",
    "어린이 같은",
    "온순한",
    "외향적인",
    "거들먹거리는",
    "거만한",
    "경멸적인",
    "경솔한",
    "경쟁심이 강한",
    "공격적인",
    "까다로운",
    "끈질긴",
    "마음이 차가운",
    "무자비한",
    "변덕스러운",
    "방어적인",
    "보수적인",
    "개방적인",
    "뻔뻔스러운",
    "사악한",
    "서투른",
    "수다스러운",
    "예민한",
    "소심한",
    "사교성이 없는",
    "자비로운",
    "냉정한",
    "도도한",
    "자상한",
    "독립적인",
    "정중한",
    "장엄한",
    "예리한",
    "경망스러운",
    "아담한",
    "얌전한",
    "흉악한",
    "시시한",
    "순박한",
    "익살스러운",
    "치사한",
    "매정한",
    "염치없는",
    "대범한",
    "수수한",
    "파격적인",
    "품위있는",
    "훈훈한",
    "독재적인",
    "단정적인",
    "모험적인",
    "경쾌한",
    "총명한",
    "영리한",
    "호기심 있는",
    "의존적인",
    "충실한",
    "상상력이 풍부한",
    "천진난만한",
    "우유부단한",
    "한결같은",
    "애교있는",
    "즉흥적인",
    "장난스러운",
    "느끼한",
    "매콤한",
    "새콤한",
    "신선한",
    "정신없는",
    "확실한",
    "황당한",
    "빛나는",
    "속삭이는",
    "환상적인",
    "찬란한",
    "조화로운",
    "신비한",
    "열정적인",
    "앙증맞은",
    "숭고한",
    "현명한",
    "평온한",
    "절제하는",
    "현실적인",
    "이기적인",
    "다정다감한",
    "날씬한",
    "가치있는",
    "유감스러운",
    "만족스러운",
    "촉촉한",
    "칭찬하는",
    "중립적인",
    "근사한",
    "희망적인",
    "반짝이는"
  ],
  "noun": [
    "아벨",
    "에이스",
    "아다",
    "아담",
    "아델",
    "아델리오",
    "아돌프",
    "아도니스",
    "아도라",
    "아가타",
    "애기",
    "아이다",
    "앨리쉬",
    "에이미",
    "알란",
    "앨버트",
    "앨비노",
    "알렉스",
    "알렉산드라",
    "알프레드",
    "알리",
    "앨리스",
    "앨리카",
    "앨리",
    "알로하",
    "앨빈",
    "아만다",
    "아미",
    "아모스",
    "에이미",
    "아나이스",
    "안드라",
    "앤드류",
    "앤디",
    "엔젤",
    "안젤리카",
    "애니카",
    "안나",
    "애니",
    "안토니",
    "아폴로",
    "아리아",
    "아리엘",
    "아리스타",
    "아놀드",
    "아비드",
    "아샤",
    "아스터",
    "아스틴",
    "오로라",
    "아바",
    "바바",
    "베일리",
    "발디",
    "밤비",
    "바바라",
    "바비",
    "발리",
    "바니",
    "바론",
    "바실",
    "박스터",
    "보",
    "베베",
    "벡",
    "베키",
    "벨리타",
    "벨라",
    "벨",
    "베네치아",
    "베니",
    "버그",
    "베시",
    "비안나",
    "비앙카",
    "비비안",
    "빌리",
    "빙고",
    "비숍",
    "블리스",
    "블론디",
    "보니타",
    "보노",
    "보리스",
    "보스",
    "브라이트",
    "브루노",
    "벅",
    "버디",
    "버니",
    "시저",
    "칼리",
    "칼릭스",
    "칼라",
    "칼리아",
    "카밀라",
    "캡틴",
    "카라",
    "카멜",
    "카르멘",
    "캐시",
    "캐서린",
    "세실",
    "셀레스틴",
    "셀리나",
    "샤샤",
    "챔프",
    "찰스",
    "찰리",
    "체이스",
    "샤비",
    "첼시",
    "쉐리",
    "칠리",
    "클로에",
    "크리시",
    "처비",
    "신디",
    "클라라",
    "클락",
    "클라우디아",
    "클레오",
    "클레타",
    "클리프",
    "코코",
    "코디",
    "콜린",
    "코니",
    "쿠키",
    "코비",
    "코이",
    "코요테",
    "크림슨",
    "크리스핀",
    "크리스탈",
    "큐티",
    "사이클론",
    "시마",
    "데이지",
    "달리",
    "다니카",
    "다비",
    "다리아",
    "다린",
    "다리오",
    "다윈",
    "데이브",
    "다비드",
    "딘",
    "델라",
    "델링",
    "델핀",
    "데니스",
    "덴버",
    "데리",
    "데바",
    "덱스터",
    "디알로",
    "딕",
    "디노",
    "딕시",
    "돈나",
    "도리스",
    "도로시",
    "더글라스",
    "듀크",
    "더스틴",
    "딜리스",
    "에반",
    "에보니",
    "에코",
    "에단",
    "에델린",
    "에덴",
    "에드워드",
    "에드윈",
    "엘리스",
    "엘도라",
    "엘프",
    "엘린",
    "엘리샤",
    "엘리자베스",
    "엘르",
    "엘로이",
    "엘사",
    "엘비스",
    "엘리시아",
    "에밀리",
    "에릭",
    "에리스",
    "에로스",
    "에스테반",
    "에스더",
    "에바",
    "에반",
    "이브",
    "파렐",
    "파비앙",
    "페도라",
    "펠리체",
    "펠릭스",
    "펠라",
    "피델리오",
    "필리아",
    "플레타",
    "플로렌스",
    "플로리아",
    "포레스트",
    "프리맨",
    "가브리엘",
    "갈리",
    "젬",
    "젬마",
    "조지",
    "길버트",
    "길리",
    "지오반니",
    "글로리아",
    "구피",
    "그레이스",
    "그라니아",
    "그레고리",
    "할리",
    "할로나",
    "해피",
    "할리",
    "하모니",
    "해롤드",
    "해리",
    "헤바",
    "헬렌",
    "헬리아",
    "헤라",
    "히어로",
    "헤스티아",
    "홀리스",
    "허니",
    "호프",
    "휴버트",
    "휴",
    "휴이",
    "이안",
    "일리아나",
    "인디라",
    "잉그리드",
    "이리나",
    "아이리스",
    "이삭",
    "이사벨",
    "이사도라",
    "이시스",
    "제이스",
    "잭",
    "잭슨",
    "재클린",
    "제이드",
    "제인",
    "쟈스민",
    "제스퍼",
    "제퍼슨",
    "제프리",
    "제니퍼",
    "제니",
    "제레미",
    "제리코",
    "제리",
    "제스",
    "제시카",
    "제시",
    "조디",
    "조안나",
    "졸리",
    "조르단",
    "조이",
    "쥬드",
    "쥴리아",
    "쥴리아나",
    "쥴리엣",
    "져스틴",
    "칼리",
    "카라",
    "카레나",
    "카리스",
    "카시아",
    "케이트",
    "켈란",
    "켈리",
    "케리",
    "케빈",
    "키티",
    "클라우스",
    "코리",
    "쿠퍼",
    "키라",
    "라키아",
    "랄라",
    "라미스",
    "라니",
    "래피",
    "라라",
    "라비나",
    "리",
    "리나",
    "렐리아",
    "레오",
    "레오폴드",
    "레브",
    "리디아",
    "릴리",
    "리나",
    "린다",
    "리사",
    "로이드",
    "로니",
    "로티",
    "루이스",
    "로웰",
    "루시아",
    "루시퍼",
    "루시",
    "루카스",
    "루나",
    "마벨",
    "마돈나",
    "매기",
    "마카이오",
    "맬리사",
    "말로",
    "마나",
    "만델리나",
    "마농",
    "마르시아",
    "마가레트",
    "메어리",
    "마틸다",
    "마야",
    "멜리나",
    "메리엘",
    "미키",
    "마이티",
    "미니",
    "미란다",
    "미시",
    "미스티",
    "몰리",
    "모네",
    "모니카",
    "모리스",
    "머핀",
    "뮬란",
    "머피",
    "나디아",
    "날로",
    "나미",
    "나나",
    "나니",
    "나오미",
    "나라",
    "나르시스",
    "나비드",
    "닐",
    "니마",
    "네로",
    "니아",
    "니콜라스",
    "닉키",
    "니나",
    "오델리아",
    "올가",
    "올리브",
    "올리버",
    "오스카",
    "파블로",
    "팔로마",
    "파멜라",
    "패트릭",
    "파벨",
    "페기",
    "펠로",
    "펜다",
    "페피",
    "페트라",
    "필라",
    "필립",
    "핑키",
    "플루토",
    "포코",
    "폴로",
    "푸키",
    "포피",
    "프리모",
    "프린스",
    "프린세스",
    "퍼피",
    "라비아",
    "레이나",
    "랄프",
    "람보",
    "라니아",
    "라비",
    "레드포드",
    "레기",
    "레이",
    "레미",
    "렉스",
    "리차드",
    "리키",
    "링고",
    "리오",
    "리사",
    "로비",
    "로버트",
    "로빈",
    "록키",
    "로자",
    "롤로",
    "로미오",
    "로지",
    "록시",
    "로이",
    "루비",
    "루돌프",
    "루디",
    "리안",
    "사브리나",
    "샐리",
    "살바토르",
    "샘",
    "삼손",
    "샌디",
    "사라",
    "사샤",
    "스칼렛",
    "스쿠프",
    "세바스챤",
    "셀리나",
    "셀마",
    "세레나",
    "세브리노",
    "샤이나",
    "샤사",
    "쉐리",
    "실키",
    "심바",
    "사이먼",
    "스니퍼",
    "솔로몬",
    "소니아",
    "써니",
    "소피",
    "소라",
    "스파키",
    "스푸키",
    "스포티",
    "스텔라",
    "스티븐",
    "스팅",
    "스톰",
    "슈가",
    "써니",
    "스위티",
    "실베스터",
    "실비아",
    "탈리아",
    "탈리",
    "타네시아",
    "타니아",
    "테드",
    "티니",
    "테라",
    "테스",
    "토마스",
    "토모",
    "트리샤",
    "트루디",
    "우바",
    "움베르토",
    "발렌시아",
    "바네사",
    "벨리카",
    "베라",
    "베르디",
    "베로니카",
    "빅토리아",
    "빈센트",
    "바이올렛",
    "비토",
    "비비",
    "왈도",
    "월터",
    "위니",
    "웬디",
    "윌리엄",
    "윌리",
    "윈스톤",
    "우디",
    "야로",
    "예티",
    "유키",
    "자자",
    "제키",
    "젤리아",
    "제나",
    "제니아",
    "제논",
    "제플린",
    "제우스",
    "질리",
    "지나",
    "지지",
    "조우",
    "조로",
    "줄루"
  ]
};
