import React, { FC } from 'react';
import { format } from 'date-fns'
import { Link, useParams } from '@reach/router';
import { Breadcrumb, Layout, Empty, Tag, Card, Button, Divider, Row, Col } from 'antd';
const { Content } = Layout;

import { useGetUserDetail } from '../repository/user_repository';
import Loading from '../../../components/loading';
import UserInfo from '../component/user_info';
import LetterList from '../../letter/component/letter_list'

import './user_detail_screen.less';
import { basePath } from '../../../common';

const IndexPage: FC = () => {
  const params = useParams()
  console.log(params.userId);
  const { loading, items } = useGetUserDetail(params.userId);
  if (loading) {
    return <Loading />
  }

  console.log(items);

  if (items.length > 0) {
    return (
      <Content style={{ margin: '0 16px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item><Link to={basePath('/users')}>사용자</Link></Breadcrumb.Item>
          <Breadcrumb.Item>{params.userId}</Breadcrumb.Item>
        </Breadcrumb>
        <UserInfo item={items[0]} onlyInfo={false} />
        <Divider />
        <LetterList user={items[0]} />
      </Content>
    );
  } else {
    return (
      <Content style={{ margin: '0 16px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item><Link to={basePath('/users')}>사용자</Link></Breadcrumb.Item>
          <Breadcrumb.Item>{params.userId}</Breadcrumb.Item>
        </Breadcrumb>
        <Empty />
      </Content>
    );
  }
}

export default IndexPage;

