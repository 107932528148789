import React, { FC, useState } from 'react';
import { format } from 'date-fns'
import { Table, Breadcrumb, Layout, Tag, Input, Select, Divider, Pagination, Space, Button } from 'antd';
import { Link, navigate } from '@reach/router';

import { stateStr, basePath } from '../../../common';
import { useGetLetters } from '../repository/letter_repository';
import Loading from '../../../components/loading';
import { Letter } from '../model/letter';
import { useQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params';

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;

const ListView: FC = () => {
  const limit = 100;
  const [query, setQuery] = useQueryParams({
    keyword: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
  });

  const { loading, items, total, refrash } = useGetLetters({
    limit: limit,
    offset: (query.page - 1) * limit,
    keyword: query.keyword,
  });

  const onSearch = (value: string) => {
    refrash({
      limit: limit,
      offset: (query.page - 1) * limit,
      keyword: value,
    })

    setQuery({ keyword: value });
  }

  const onChangePage = (page: number) => {
    refrash({
      limit: limit,
      offset: (page - 1) * limit,
      keyword: query.keyword,
    })
    setQuery({ page: page });
  }

  const data = items.map((item, index) => {
    return {
      key: item.id,
      ...item,
    }
  })

  const columns: any[] = [
    {
      title: '순서',
      dataIndex: 'id',
      key: 'id',
      render: (id: number, _, index: number) => (
        <span>{total - (index + (query.page - 1) * limit)}</span>
      )
    },
    {
      title: '보낸 사용자',
      dataIndex: 'user_id',
      key: 'user_id',
      render: (value: string, r: Letter,) => (
        <a onClick={() => navigate(basePath(`/users/${value}`))}>{r.user.nickname || '-'}</a>
      )
    },
    {
      title: '내용',
      dataIndex: 'message',
      key: 'message',
      render: (value: string) => (
        <span>{value || '-'}</span>
      )
    },
    {
      title: '생성일',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: string) => (
        <span>{format(new Date(created_at), 'yyyy-MM-dd HH:mm')}</span>
      )
    },
    {
      title: '설정',
      key: 'setting',
      render: (value, r: Letter) => (
        <Button danger onClick={() => {

        }}>
          삭제
        </Button>
      )
    },
  ];

  return (
    <Content style={{ margin: '0 16px' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>편지 / {total}개</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <Space direction="vertical">
          <Input.Group compact>
            <Search placeholder="내용검색" loading={loading} onSearch={onSearch} style={{ width: 200 }} />
          </Input.Group>
        </Space>
        <Divider />
        <Table
          pagination={false}
          dataSource={data}
          columns={columns}
          loading={loading}
        // onRow={(r) => ({
        //   onClick: () => navigate(basePath(`/letters/${r.id}`)),
        // })}
        />
        <Pagination current={query.page} total={total} onChange={onChangePage} pageSize={limit} pageSizeOptions={[]} />
      </div>
    </Content>
  );
}

export default ListView;
