import React, { FC, useState } from 'react';
import { format } from 'date-fns'
import { Link } from '@reach/router';
import _ from 'lodash';
import { Breadcrumb, Layout, Button, Row, Divider, message, Space, Tabs } from 'antd';

import { useGetPolicies, useUpsertPolicies } from '../repository/policy_repository';

import PolicyEditComponent from '../component/policy_editor';
import Loading from '../../../components/loading';
import { basePath } from '../../../common';

const { Content } = Layout;
const { TabPane } = Tabs;

const IndexPage: FC = () => {
  const { loading, items, refrash } = useGetPolicies({
    "where": {
      "type": {
        "_eq": 'privacy',
      }
    }
  });

  if (loading) {
    return <Loading />
  } else {
    return (
      <Content style={{ margin: '0 16px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item><Link to={basePath('/privacy')}>개인정보 이용약관</Link></Breadcrumb.Item>
        </Breadcrumb>
        <Tabs defaultActiveKey="1" type="card">
          <TabPane tab="한국어" key="1">
            <PolicyEditComponent type="privacy" title="개인정보취급방침" language="ko" item={_.find(items, (item) => {
              return item.language == 'ko';
            })} />
          </TabPane>
          {/* <TabPane tab="영어" key="2">
            <PolicyEditComponent type="privacy" title="privacy" language="en" item={_.find(items, (item) => {
              return item.language == 'en';
            })} />
          </TabPane> */}
        </Tabs>
      </Content>
    );
  }



}

export default IndexPage;
