import React, { FC, useState } from 'react';
import _ from 'lodash';
import { format } from 'date-fns'
import firebase from "gatsby-plugin-firebase";
import { Tag, Card, Button, Space, Descriptions, Input, Modal, message, Image, Avatar } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { User } from '../model/user';
import { useUpdateUser } from '../repository/user_repository';

const { TextArea } = Input;
const { confirm } = Modal;

const UserInfo: FC<{ item: User, onlyInfo: boolean }> = ({ item, onlyInfo }) => {
  console.log(item);
  return (
    <Card type="inner" title="사용자 정보" extra={
      <Space>
        {!onlyInfo && <UserDelete item={item} />}
        {/* {!onlyInfo && <UserBlock item={item} />} */}
      </Space>
    }>
      <Descriptions
        bordered
        size="small"
      >
        <Descriptions.Item label='프로필'>
          <Avatar src={item.avatar} ></Avatar>
        </Descriptions.Item>
        {!onlyInfo && <Descriptions.Item label='UID'>
          {item.uid}
        </Descriptions.Item>}
        <Descriptions.Item label='앱버전'>
          {item?.user_info.app_version}
        </Descriptions.Item>
        <Descriptions.Item label='닉네임'>
          {item.nickname}
        </Descriptions.Item>
        <Descriptions.Item label='이메일'>
          {item.email}
        </Descriptions.Item>
        <Descriptions.Item label='성별'>
          {item.gender || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='생일'>
          {item.introduction.birthday ? format(new Date(item.introduction.birthday), 'yyyy-MM-dd') : '-'}
        </Descriptions.Item>
        <Descriptions.Item label='다이아'>
          {item?.user_items[0] && item?.user_items[0].amount}개
        </Descriptions.Item>
        <Descriptions.Item label='가입형태'>
          {item?.user_info.provider || '익명'}
        </Descriptions.Item>
        <Descriptions.Item label='플랫폼'>
          {item?.user_info.platform}
        </Descriptions.Item>
        {!onlyInfo && <Descriptions.Item label='업데이트'>
          <span>{format(new Date(item.updated_at), 'yyyy-MM-dd HH:mm')}</span>
        </Descriptions.Item>}
        {!onlyInfo && <Descriptions.Item label='생성 날짜'>
          <span>{format(new Date(item.created_at), 'yyyy-MM-dd HH:mm')}</span>
        </Descriptions.Item>}
        {!onlyInfo && <Descriptions.Item label='최근 접속일'>
          <span>{format(new Date(item.user_info.latest_at), 'yyyy-MM-dd HH:mm')}</span>
        </Descriptions.Item>}
      </Descriptions>
    </Card>
  );
}

export default UserInfo;

const UserDelete: FC<{ item: User }> = ({ item }) => {
  const [loading, setLoading] = useState(false);
  const [updateUser, isLoading] = useUpdateUser();

  const onClick = async () => {
    if (item) {
      confirm({
        title: '삭제를 진행 할까요?',
        icon: <ExclamationCircleOutlined />,
        content: '삭제 시 복구가 불가능합니다.',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          setLoading(true);

          try {
            const httpsCallable = firebase.functions().httpsCallable('withdrawal');

            await httpsCallable({
              'uid': item.uid,
            });

            message.success('withdrawal success');

            window.location.reload();
          } catch (err) {
            message.error(err.message);
          } finally {
            setLoading(false);
          }
        },
        onCancel() { },
      });
    }
  }

  return (
    <Button type="primary" danger loading={loading} ghost={true} onClick={onClick}>삭제</Button>
  )
}
