import gql from 'graphql-tag';

export const GET_CHATS = gql`
  query GetChats($limit: Int, $offset: Int, $where: chats_bool_exp = {}) {
    items: chats(order_by: {created_at: desc}, offset: $offset, limit: $limit, where: $where) {
      id
      updated_at
      created_at
      user_id
      other_user_id
      messages(limit: 1, offset: 0, order_by: {created_at: desc}) {
        id
        chat_id
        send_user_id
        receive_user_id
        updated_at
        created_at
        message
        is_view
      }
    }
  }
`


export const GET_CHAT_BY_ID = gql`
  query GetChatById($where: chats_bool_exp!) {
    items: chats(where: $where, limit: 1) {
      id
      updated_at
      created_at
      user_id
      other_user_id
      messages(order_by: {created_at: asc}) {
        id
        chat_id
        send_user_id
        receive_user_id
        updated_at
        created_at
        message
        is_view
        userBySendUserId {
          avatar
          updated_at
          created_at
          nickname
          provider
          uid
          id
          gender
          fcm_token
          email
          birthday
          personality
          attendance_at
        }
        userByReceiveUserId{
          avatar
          updated_at
          created_at
          nickname
          provider
          uid
          id
          gender
          fcm_token
          email
          birthday
          personality
          attendance_at
        }
      }
    }
  }
`